import React, { useEffect, useState } from 'react';
import config from './shop/config/config.json';
import { Router } from '@reach/router';
import NotFound from './game/pages/NotAllowed/NotFound';
import Landing from './game/pages/Landing/Landing';
import CanNotPlay from './game/pages/NotAllowed/CanNotPlay';
import Refund from './game/pages/Refund/Refund';
import Success from './game/pages/Success/Success';
import Legal from './game/pages/Legals/component/Legal';
import InStore from './game/pages/InStore/InStore';
import { receiptInStoreMode } from './utils/catalinaRequests';
import { refundPages } from './game/pages/Refund/RefundPagesEnum';
import Footer from './game/components/Footer/Footer';
import CasinoGame from './game/pages/Game/CasinoGame';
import ScratchGame from './game/pages/Game/ScratchGame';
import WheelGame from './game/pages/Game/WheelGame';
import FlipGame from './game/pages/Game/FlipGame';
import Teasing from './game/pages/Teasing/Teasing';
import CollectInfo from './game/pages/CollectInfo/CollectInfo';
import useMobile from './utils/hooks/useMobile';
import { applyApiConfiguration, initializeAppAndGetToken } from './utils/init';
import { defaultBackgroundColor } from './utils/clientConfig';

/**
 * The main component of the application.
 * It handles the initialization, API configuration, and rendering of the app.
 *
 * @returns {JSX.Element} The rendered app component.
 */
function App() {
  let isMobile = useMobile();
  const deviceType = isMobile ? 'mobile' : 'desktop';

  const [error, setError] = useState('');
  const [infoToken, setInfoToken] = useState('');
  const [backgroundLayerStyle, setBackgroundLayerStyle] = useState(undefined);
  const [desktopBackgroundImgLayer, setDesktopBackgroundImgLayer] =
    useState('');
  const [offer, setOffer] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    /**
     * Initializes the app, Google analytics, and gets the token from the URL.
     * Sets the token, infoToken, and isAppInitialized state variables.
     * Applies the API configuration and sets the offerContenu, desktopBackgroundImgLayer, and loaded state variables.
     * Sets the mode in store if the receipt is in store mode.
     * @returns {Promise<void>}
     */
    async function initApp() {
      // Initialize the app, Google analytics and get the token from URL
      const token = initializeAppAndGetToken();
      setInfoToken(token);
      // Apply the API configuration
      try {
        const { offer, desktopBackgroundImgLayer, loaded } =
          await applyApiConfiguration();

        console.log(offer);
        setOffer(offer);
        setDesktopBackgroundImgLayer(desktopBackgroundImgLayer);
        setLoaded(loaded);

        // Set the mode in store if the receipt is in store mode
        if (receiptInStoreMode()) {
          config.isModeInStore = true;
        }
      } catch (error) {
        console.error('Error while applying the API configuration:', error);
        if (error) {
          setError(error);
        }
      }
    }
    initApp();
  }, []);

  useEffect(() => {
    if (desktopBackgroundImgLayer) {
      let mobileBackgroundLayer = sessionStorage.getItem('bgMobileImageUrl');
      const backgroundStyle = {
        background: `top / cover no-repeat ${
          isMobile && mobileBackgroundLayer
            ? `url(${mobileBackgroundLayer})`
            : `url(${desktopBackgroundImgLayer})`
        }`,
      };
      setBackgroundLayerStyle(backgroundStyle);
    }
  }, [isMobile, desktopBackgroundImgLayer]);

  if (error) {
    return (
      <div
        className={`${deviceType}`}
        id="main-container"
        style={{ backgroundColor: defaultBackgroundColor }}
      >
        <CanNotPlay redirect />
      </div>
    );
  }

  return (
    <>
      <div
        className={`${config.gameType} ${deviceType}`}
        id="main-container"
        style={backgroundLayerStyle}
      >
        <Router>
          <NotFound default />
          <CanNotPlay path="/can-not-play" />
          <Teasing offerr={offer} path="/teasing/:offer/:retailerId" />

          <Legal
            path="/legal/:legalType"
            token={infoToken}
            isMobile={isMobile}
          />
          <InStore path="/in-store" />
          <Success path="/success-email" />
          <CasinoGame
            path="/casinogame"
            backgroundLayerStyle={backgroundLayerStyle}
            loaded={loaded}
          />
          <ScratchGame
            path="/scratchgame"
            backgroundLayerStyle={backgroundLayerStyle}
            brandLogo={offer.brandLogo}
            loaded={loaded}
          />
          <WheelGame
            path="/wheelgame"
            backgroundLayerStyle={backgroundLayerStyle}
            brandLogo={offer.brandLogo}
            loaded={loaded}
          />
          <FlipGame
            path="/flipgame"
            backgroundLayerStyle={backgroundLayerStyle}
            brandLogo={offer.brandLogo}
            loaded={loaded}
          />
          <Refund
            path="/refund-choices"
            page={refundPages.CHOICES}
            backgroundLayerStyle={backgroundLayerStyle}
          />

          <Refund
            path="/refund/:email/:retailerId/:offer"
            backgroundLayerStyle={backgroundLayerStyle}
          />
          <Refund path="/refund" backgroundLayerStyle={backgroundLayerStyle} />
          <CollectInfo
            path="/collect-info"
            loaded={loaded}
            backgroundLayerStyle={backgroundLayerStyle}
          />
          <Landing
            path="/"
            loaded={loaded}
            backgroundLayerStyle={backgroundLayerStyle}
            offerContenu={offer}
          />
        </Router>
      </div>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
