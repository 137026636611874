import CryptoJS from 'crypto-js';
import request from './request';
import config from '../shop/config/config.json';

const apiHost = process.env.REACT_APP_HOST;
const partner_key = process.env.REACT_APP_PARTNER_K;
const partner_secret = process.env.REACT_APP_PARTNER_S;
const partner_id = process.env.REACT_APP_PARTNER_ID;
const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_K;

/**
 * Performs crypted authentication for a given email.
 * If an email is provided, it will be encrypted and stored in the session storage.
 * The retailer ID and holder reference are also included in the request body.
 * Upon successful authentication, the member ID and authorization token are stored in the session storage.
 *
 * @param {string} email - The email to be encrypted and used for authentication (optional).
 * @returns {Promise<{ memberId: number }>} - A promise that resolves to an object containing the member ID.
 */
export function cryptedAuthentication(email) {
  let body = {
    retailer_id: Number(getRetailerId()),
    holder_ref: crypteItem(getHolderRef()),
  };
  if (email) {
    body = { email: crypteItem(email) };
    sessionStorage.setItem('email', email);
  }

  const encryptedAuthUrl = apiHost + 'members/crypted_authentication';
  return httpPost(encryptedAuthUrl, 'members/crypted_authentication', body)
    .then((result) => {
      sessionStorage.setItem('memberId', result.id);
      sessionStorage.setItem('Authorization', result.access_token);

      return { memberId: result.id };
    })
    .catch((err) => console.error('error', err));
}

/**
 * Performs crypted authentication for refund later.
 * @param {string} email - The email address.
 * @param {string} retailerId - The retailer ID.
 * @param {string} offerId - The offer ID.
 * @returns {Promise<void>} - A promise that resolves when the authentication is successful.
 */
export function cryptedAuthenticationRefundLater(email, retailerId, offerId) {
  let emailfDecoded = decodeURIComponent(email);
  sessionStorage.setItem('retailerId', retailerId);
  sessionStorage.setItem('offerId', offerId);
  const body = {
    retailer_id: retailerId,
    email: emailfDecoded,
  };
  const encryptedAuthUrl = apiHost + 'members/crypted_authentication';
  return httpPost(encryptedAuthUrl, 'members/crypted_authentication', body)
    .then((result) => {
      sessionStorage.setItem('memberId', result.id);
      sessionStorage.setItem('Authorization', result.access_token);
    })
    .catch((err) => console.error('error', err));
}

/**
 * Posts a lottery participation request.
 * @param {string} memberId - The ID of the member participating in the lottery.
 * @param {string} retaillerId - The ID of the retailer hosting the lottery.
 * @param {string} offerId - The ID of the offer associated with the lottery.
 * @returns {Promise} - A promise that resolves with the response from the server.
 */
export async function postLottery(memberId, retaillerId, offerId) {
  let actionPath = 'lottery/participation';
  let body = {
    member_id: memberId,
    retailer_id: retaillerId,
    offer_id: offerId,
  };

  return httpPost(apiHost + actionPath, actionPath, body);
}

/**
 * Retrieves lottery information for a specific member, retailer, and offer.
 * @param {string} memberId - The ID of the member.
 * @param {string} retaillerId - The ID of the retailer.
 * @param {string} offerId - The ID of the offer.
 * @returns {Promise<any>} - A promise that resolves to the lottery information.
 */
export async function getLottery(memberId, retaillerId, offerId) {
  let actionPath = `lottery/participation?member_id=${memberId}&retailer_id=${retaillerId}&offer_id=${offerId}`;

  return httpGet(apiHost + actionPath, actionPath);
}

/**
 * Retrieves the ecommerce offer with the specified ID.
 * If no ID is provided, it retrieves the offer with the default ID.
 * If a holder reference is available, it includes it in the request.
 *
 * @param {string} id - The ID of the offer to retrieve.
 * @returns {Promise} A promise that resolves with the retrieved offer.
 */
export async function getEcommerceOffer(id) {
  try {
    const retailerId = encodeURIComponent(getRetailerId());
    const holderRef = getHolderRef()
      ? `&holder_ref=${encodeURIComponent(getHolderRef())}`
      : '';
    const actionPath = `ecommerce/offers?retailer_id=${retailerId}${holderRef}`;

    const offerId = id || getOfferId();
    const offers = await httpGet(apiHost + actionPath, actionPath);
    return offers?.find((offer) => offer.id.toString() === offerId) || null;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getOffer(offerId) {
  //***** GET/shop_and_play/games ****//
  let actionPath = `shop_and_play/games`;

  let response = await httpGet(apiHost + actionPath, actionPath);
  let result = response.filter((item) => item.id === +offerId);
  return result[0];
}

export async function postTriggerableOffers() {
  if (getProducts()) {
    let productArray = JSON.parse(getProducts());
    let actionsIdArray = JSON.parse(getActionId());
    let action_ids = actionsIdArray?.length
      ? actionsIdArray
          .map((ai, index) => {
            return `&action_ids[${index}]=${ai}`;
          })
          .concat()
      : '';

    let retailer_ref = process.env.REACT_APP_RETAILER_REF;
    const actionPath = `shop_and_play/triggerable_games?member_id=${getMemberId()}&retailer_ref=${retailer_ref}&total_amount=${getTotalAmount()}${action_ids}&clearing_office_ref=${getClearingOfficeRef()}`;
    return httpPost(apiHost + actionPath, actionPath, {
      products: productArray,
    }).then((offers) => {
      if (!sessionStorage.getItem('offers-' + getClearingOfficeRef())) {
        let chances = offers.map((each) => ({
          id: each.id,
          odds: each.odds !== 1 ? each.odds : 'one',
        }));
        sessionStorage.setItem(
          'offers-' + getClearingOfficeRef(),
          JSON.stringify(chances),
        );
      }
    });
  }
}
export async function getTriggerableGame() {
  if (getProducts()) {
    let actionsIdArray = JSON.parse(getActionId());
    let action_ids = actionsIdArray?.length
      ? actionsIdArray
          .map((ai, index) => {
            return `&action_ids[${index}]=${ai}`;
          })
          .concat()
      : '';

    let retailer_ref = process.env.REACT_APP_RETAILER_REF;
    const actionPath = `shop_and_play/triggerable_games?member_id=${getMemberId()}&retailer_ref=${retailer_ref}&total_amount=${getTotalAmount()}${action_ids}&clearing_office_ref=${getClearingOfficeRef()}`;
    return httpGet(apiHost + actionPath, actionPath).then((offers) => {
      if (!sessionStorage.getItem('offers-' + getClearingOfficeRef())) {
        let chances = offers.map((each) => ({
          id: each.id,
          odds: each.odds !== 1 ? each.odds : 'one',
        }));
        sessionStorage.setItem(
          'offers-' + getClearingOfficeRef(),
          JSON.stringify(chances),
        );
      }
    });
  }
}
export async function getReward(offerId) {
  let memberId = getMemberId();
  let retaillerId = process.env.REACT_APP_RETAILER_ID;
  const actionPath = `shop_and_play/rewards?member_id=${memberId}&retailer_id=${retaillerId}&offer_id=${offerId}`;
  return httpGet(apiHost + actionPath, actionPath).then((offers) => {
    return offers;
  });
}

export async function postBasket() {
  const basketUrl = apiHost + 'members/' + getMemberId() + '/basket';
  const body = [
    {
      retailer_id: Number(getRetailerId()),
      offer_id: Number(getOfferId()),
      clearing_office_ref: getClearingOfficeRef(),
    },
  ];
  return httpPost(basketUrl, 'members/' + getMemberId() + '/basket', body);
}

export async function putBasket(receipt_id, receipt) {
  const basketUrl =
    apiHost + 'members/' + getMemberId() + '/receipts/' + receipt_id;
  const body = {
    snapshots: [receipt],
  };
  return httpPut(
    basketUrl,
    'members/' + getMemberId() + '/receipts/' + receipt_id,
    body,
  );
}

export async function getBasket(type) {
  let actionPath = `members/${getMemberId()}/basket?retailer_id=${getRetailerId()}&offer_id=${getOfferId()}`;
  if (type === 'reward') {
    actionPath = 'members/' + getMemberId() + '/basket';
  }

  return httpGet(apiHost + actionPath, actionPath);
}

export async function patchMember(data, memberId) {
  let basketUrl;
  if (memberId) {
    basketUrl = 'members/' + memberId;
  } else {
    basketUrl = 'members/' + getMemberId();
    data.holders = [
      {
        ref: getHolderRef(),
        retailer_id: Number(getRetailerId()),
      },
    ];
  }
  if (!!data.email) {
    sessionStorage.setItem('email', data.email);
  }

  return httpPatch(apiHost + basketUrl, basketUrl, data);
}
export async function postAttachReward(data) {
  let actionPath = 'shop_and_play/reward_transfers';
  return httpPost(apiHost + actionPath, actionPath, data);
}

export async function postAndUploadReceipt(body, receiptId) {
  let actionPath = `members/${getMemberId()}/receipts`;
  if (receiptId) {
    actionPath = `members/${getMemberId()}/receipts/${receiptId}`;
  }

  return receiptId
    ? httpPut(apiHost + actionPath, actionPath, body)
    : httpPost(apiHost + actionPath, actionPath, body);
}

export async function sendPin(pin) {
  const actionPath = 'burnable_code';
  const body = {
    member_id: getMemberId(),
    retailer_id: getRetailerId(),
    code: pin,
  };
  return httpPost(apiHost + actionPath, actionPath, body);
}

export async function sendMailLaterRefund(data) {
  let body = {
    to: [
      {
        email: data.email,
      },
    ],
    params: {
      url: data.url,
    },
    templateId: 1,
  };
  let url = 'https://api.sendinblue.com/v3/smtp/email';
  const headers = {
    accept: 'application/json',
    'api-key':
      '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',
    'content-type': 'application/json',
  };
  return request(url, {
    method: 'POST',
    headers,
    body,
  });
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export async function sendReceipt(receipt, receiptId) {
  let finalResponse;
  let mode;
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const basket = await getBasket();
  if (basket && basket[0] && basket[0].status === 1) {
    let body = {
      snapshots: [receipt],
      rewards: [
        {
          reward_id: basket[0].id,
        },
      ],
    };
    await postAndUploadReceipt(body);
  } else if (!receiptId) {
    mode = 'POST';
    await postBasket();
    let respGetBasket;
    let tries = 0;
    while (tries < 5) {
      respGetBasket = await getBasket('reward');
      if (respGetBasket && respGetBasket.length > 0 && respGetBasket[0].id) {
        break;
      }
      tries++;
      await delay(300); // Attendre 1 seconde avant de réessayer
    }
    if (!respGetBasket || respGetBasket.length === 0 || !respGetBasket[0].id) {
      const globalError = new Error(
        'Oups ! Un problème technique est survenu, merci de réessayer ultérieurement.',
      );
      globalError.isGlobalError = true;
      throw globalError;
    }

    let body = {
      snapshots: [receipt],
      rewards: [
        {
          reward_id: respGetBasket[0].id,
        },
      ],
    };

    await postAndUploadReceipt(body);
  } else {
    mode = 'PUT';
    await putBasket(receiptId, receipt);
  }
  await delay(config.timerPostReceipt * 1000);
  let respGetWallet = await getWallet('print');
  if (respGetWallet.pending.length > 0) {
    console.info('---- Valid receipt --- ');
    finalResponse = 'validate';
  } else if (!!respGetWallet.refused) {
    console.info('---- Refused receipt ----');
    finalResponse = {
      receipt_id: respGetWallet.refused[0].receipt.id,
      comment: respGetWallet.refused[0].receipt.comment,
      mode,
    };
  }
  return finalResponse;
}

export async function getWallet(type) {
  return httpGet(
    apiHost + 'members/' + getMemberId() + '/wallet',
    'members/' + getMemberId() + '/wallet',
  ).then((results) => {
    let lotteryId = 0;
    if (results.lottery_rejected && results.lottery_rejected.length > 0) {
      lotteryId = results.lottery_rejected[0].id;
    }
    if (type === 'print') {
      return results;
    }
    return (
      results.validated &&
      results.validated.length > 0 &&
      results.validated[0].id > lotteryId
    );
  });
}

async function httpGet(url, apiActionPath) {
  const headers = await getHeaders(apiActionPath, 'GET', 0);
  return request(url, { headers });
}

async function httpPost(url, apiActionPath, body) {
  const payload = JSON.stringify(body);
  const headers = await getHeaders(apiActionPath, 'POST', payload.length);
  return request(apiHost + apiActionPath, {
    method: 'POST',
    headers,
    body,
  });
}

async function httpPatch(url, apiActionPath, body) {
  const payload = JSON.stringify(body);
  const headers = await getHeaders(apiActionPath, 'PATCH', payload.length);
  return request(apiHost + apiActionPath, {
    method: 'PATCH',
    headers,
    body,
  });
}

async function httpPut(url, apiActionPath, body) {
  const payload = JSON.stringify(body);
  const headers = await getHeaders(apiActionPath, 'PUT', payload.length);
  return request(apiHost + apiActionPath, {
    method: 'PUT',
    headers,
    body,
  });
}

function generatePartnerToken(
  methodRest,
  apiActionPath,
  timeStamp,
  dataLength,
) {
  const message = `/api/v1/${apiActionPath}${methodRest}${dataLength}${partner_secret}${timeStamp}`;
  const hmacSHA1 = CryptoJS.HmacSHA1(message, partner_key);
  return CryptoJS.enc.Hex.stringify(hmacSHA1);
}

async function getHeaders(apiActionPath, methodRest, dataLength) {
  const timeStamp = new Date().toUTCString();
  const token = sessionStorage.getItem('Authorization');
  const partnerToken = generatePartnerToken(
    methodRest,
    apiActionPath,
    timeStamp,
    dataLength,
  );
  return new Headers([
    ['Accept', 'application/json'],
    ['Access-Control-Allow-Origin', '*'],
    ['Authorization', `Bearer ${token}`],
    ['Cache-Control', 'no-store'],
    ['Content-Type', 'application/json'],
    ['Ocp-Apim-Subscription-Key', subscriptionKey],
    ['X-Partner-Access-Token', partnerToken],
    ['X-Content-Type-Options', 'nosniff'],
    ['X-Cwallet-Partner-Id', partner_id],
    ['X-Cwallet-Timestamp', timeStamp],
    ['X-Frame-Options', 'DENY'],
    ['X-XSS-Protection', '1'],
    ['Content-Length', 0],
  ]);
}

export function generateCryptedHolderRef(holder_ref) {
  const encodedWord = CryptoJS.enc.Utf8.parse(holder_ref); // encodedWord Array object
  return CryptoJS.enc.Base64.stringify(encodedWord);
}

export function crypteItem(item) {
  const encrypted_source = CryptoJS.AES.encrypt(
    item,
    CryptoJS.enc.Utf8.parse(partner_key),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    },
  );

  return encrypted_source.toString();
}
export function decrypteItem(item) {
  const deccrypted_source = CryptoJS.AES.decrypt(
    item,
    CryptoJS.enc.Utf8.parse(partner_key),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    },
  );

  return deccrypted_source.toString(CryptoJS.enc.Utf8);
}

export function receiptInStoreMode() {
  let receipt = sessionStorage.getItem('receipt');
  if (receipt === 'undefined') {
    return undefined;
  }
  return receipt;
}

export function getMemberId() {
  let memberId = sessionStorage.getItem('memberId');
  if (memberId === 'undefined') {
    return undefined;
  }
  return memberId;
}
export function getRewardId() {
  const reward_id = sessionStorage.getItem('rewardId');

  if (reward_id === 'undefined' || reward_id === 'null') {
    return undefined;
  }
  return reward_id;
}
export function getHolderRef() {
  let holderRef = sessionStorage.getItem('holderRef');
  if (holderRef === 'undefined') {
    return undefined;
  }
  return holderRef;
}

export function getOfferOdds() {
  let offerId = getOfferId();
  let cor = getClearingOfficeRef();
  let listOffer = sessionStorage.getItem('offers-' + cor);
  let listOfferFormat = JSON.parse(listOffer);
  let oddsOffer = listOfferFormat?.filter((item) => item.id === +offerId)?.[0]
    ?.odds;
  return oddsOffer;
}

/**
 * Retrieves the active spotlight for a specific offer.
 * @param {string} offerId - The ID of the offer.
 * @returns {Promise<any>} - A promise that resolves to the active spotlight.
 */
export async function getActiveSpotlight() {
  const actionPath = `spotlights/web?is_cwallet=true`;
  const spotlights = await httpGet(apiHost + actionPath, actionPath);
  const now = new Date();

  // Find the active spotlight
  const activeSpotlight = spotlights.find((spotlight) => {
    const startedAt = new Date(spotlight.started_at);
    const endedAt = new Date(spotlight.ended_at);
    return startedAt <= now && now <= endedAt;
  });
  return activeSpotlight || null;
}

export function getOfferId() {
  return sessionStorage.getItem('offerId');
}
export function getOfferBackgroundColor() {
  return sessionStorage.getItem('offerBackgroundColor');
}
export function getActionId() {
  return sessionStorage.getItem('action_ids');
}
export function getTotalAmount() {
  return sessionStorage.getItem('totalAmount');
}
export function getProducts() {
  return sessionStorage.getItem('products');
}
export function getRetailerId() {
  return sessionStorage.getItem('retailerId');
}
export function getRetailerRef() {
  return sessionStorage.getItem('retailerRef');
}
export function getClearingOfficeRef() {
  return sessionStorage.getItem('clearingOfficeRef');
}
export function getCGU() {
  return sessionStorage.getItem('CGU') || '';
}
export function getScratchImg() {
  return sessionStorage.getItem('scratchImg') || '';
}
