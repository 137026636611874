import React, { useState } from 'react';
import { useNavigate } from '@reach/router';
import config from '../../../shop/config/config.json';
import FormCollectInfo from './FormCollectInfo'; // Assurez-vous d'ajuster le chemin d'importation
import SlotMachine from '../../components/Slotmachine/SlotMachine';
import Loading from '../../components/Loading/Loading';
import {
  cryptedAuthentication,
  getMemberId,
  patchMember,
} from '../../../utils/catalinaRequests';
import {
  isCityCollected,
  isNameCollected,
  isNewsletterCollected,
} from '../../../utils/clientConfig';
import { receiptInStoreMode } from '../../../utils/catalinaRequests';
import { gameRedirect } from '../../../utils/utils';
import './assets/styles.scss';

export default function CollectInfo({ loaded }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function onSubmit(data) {
    setLoading(true);
    try {
      await cryptedAuthentication(data.email);
      let body = {};
      if (isNameCollected) {
        const names = [
          data.lastName.toLowerCase().trim(),
          data.firstName.toLowerCase().trim(),
        ];
        body = {
          lastname: names[0],
          firstname: names[1],
          email: data.email.toLowerCase().trim(),
          ...body,
        };
      }

      if (isCityCollected) {
        body = {
          city: `${data.street.toLowerCase()} - ${data.street_info.toLowerCase()}`,
          ...body,
        };
      }

      if (isNewsletterCollected) {
        body = { news_optin_partners: data.newsletter, ...body };
      }

      await patchMember(body, getMemberId());
      if (receiptInStoreMode()) {
        return navigate('/in-store');
      }
      navigate(gameRedirect(config.gameType));
    } catch (e) {
      console.error('ERROR :', e);
    } finally {
      setLoading(false);
    }
  }

  if (!loaded) {
    return <Loading />;
  }

  return (
    <SlotMachine
      content={<FormCollectInfo onSubmit={onSubmit} loading={loading} />}
    />
  );
}
